@import "node_modules/@coreui/coreui/scss/coreui";

.c-sidebar .c-sidebar-brand {
  background: $primary;
}
.c-sidebar-nav { 
  background-color: $primary;
}

.c-sidebar-nav-item {
  &:hover {
    background-color: rgba($color: $white, $alpha: 0.2);
  }
}

.c-sidebar-minimizer {
  background-color: $primary !important;
}